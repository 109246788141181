import posthog from 'posthog-js'

enum CookieType {
  analytics = 'analytics-consent',
  advertisement = 'advertisement-consent',
}

export type ConsentSettings = {
  advertisement: boolean
  analytics: boolean
}

declare global {
  interface Window {
    showCookieBanner: any
    dataLayer: any[]; // Google Tag Manager dataLayer
  }
}

const cookieProviders = {
  postHog: (consentSettings: ConsentSettings) => {
    if (consentSettings?.analytics) {
      posthog?.opt_in_capturing()
    } else {
      posthog?.opt_out_capturing()
    }
  }
}

// TODO: (Optional) make these promise based and report error to user if something went wrong while trying to disable
//        and log error to error tracking service
export const updateProviders = (consentSettings: ConsentSettings) => {
  for (const [provider, update] of Object.entries(cookieProviders)) {
    try {
      update(consentSettings)
    } catch (e) {
      console.log('something went wrong with enabling cookie consent with ', provider)
    }
  }
}

export const disableProviders= () => {
  const declineAll:ConsentSettings = {advertisement: false, analytics: false}; 
  for (const [provider, update] of Object.entries(cookieProviders)) {
    try {
      update(declineAll);
    } catch (e) {
      console.log('something went wrong with disabling cookie consent with ', provider)
    }
  }
}

export const readConsent: () => ConsentSettings | undefined = () => {
  const advertisementCookie = getCookie(CookieType.advertisement)
  const analyticsCookie = getCookie(CookieType.analytics)

  return {
    advertisement: advertisementCookie === 'true' ? true : false,
    analytics: analyticsCookie === 'true' ? true : false,
  }
}

export const saveConsent = (consentSettings: ConsentSettings) => {
  // Save settings to cookies
  setCookie(CookieType.advertisement, consentSettings.advertisement.toString(), 180);
  setCookie(CookieType.analytics, consentSettings.analytics.toString(), 180);
  
  // Update providers
  updateProviders(consentSettings);

  // Tell GTM consent has changed
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'event': 'consent_changed'});
}

const cookieExists = (name:string) => {
  return document.cookie.split(';').some((item) => item.trim().startsWith(name + '='));
}

export const consentCookiesExist = () => {
  return (cookieExists(CookieType.advertisement) && cookieExists(CookieType.analytics));
}

export const getCookie = name => {
  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim()
    // Check if this cookie has the desired name
    if (cookie.startsWith(name + '=')) {
      // Extract and return the cookie value
      return cookie.substring(name.length + 1)
    }
  }
  // If the cookie is not found, return null or an empty string
  return null
}

export function setCookie(name: string,value: string, days : number) {
  let expires = "";
  if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
