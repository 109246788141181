/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as React from 'react'
import { I18nProvider } from './src/lib/context/i18n'
import { stripOutHomeForRootPath } from './src/lib/utils/translatedSlugs'
import { useScript } from './src/lib/utils/useScript'
import { formatPath } from './src/lib/utils/internalLinks'
import { StoryblokProvider } from './src/lib/context/StoryblokContext'
import posthog from 'posthog-js'
import { useCookieBanner } from './src/features/CookieBanner/useCookieBanner'
import { CookieBannerContext } from './src/features/CookieBanner/CookieBannerContext'
import { getCookie } from './src/features/CookieBanner/cookieService'
import * as Sentry from "@sentry/gatsby";


// Do not init posthog in the dev mode
const isNotLocalEnv = !window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')

// As key/token is shared with client side it is not really a secret so it is fine to be here
// Once we add multiple env for posthog if we want to do so we can move it to env file
if (isNotLocalEnv) {
  posthog.init('phc_w5uJGyVUVHUjWjXsYvVgJGdgV0aYIQNR9ayJmL2Vf8f', { api_host: 'https://eu.posthog.com' })
  
  const hubspotId = getCookie('hubspotutk')
  const googleAnalyticsId = getCookie('_gid')

  posthog.register({
    hubspotId: hubspotId,
    gid: googleAnalyticsId,
  })
}

Sentry.init({
  dsn: "https://ee85e70ec49e73456da09b7a6a87bfbd@o4506201331400704.ingest.sentry.io/4506201338806272",
  integrations: [
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  enabled: isNotLocalEnv,
});

export const onRouteUpdate = () => {
  if (isNotLocalEnv) {
    posthog.capture('$pageview')
  }
}

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  const defaultSlug = formatPath(stripOutHomeForRootPath(pageContext.defaultSlug))
  const cookieBanner = useCookieBanner()

  useScript('//cdn-4.convertexperiments.com/js/10043310-10044619.js', 'convertTracking')

  return (
    <I18nProvider
      pageLanguage={pageContext.lang}
      translatedSlugs={pageContext.translated_slugs}
      defaultSlug={defaultSlug || 'default'}
    >
      <CookieBannerContext.Provider value={cookieBanner}>
        <StoryblokProvider>{element}</StoryblokProvider>
      </CookieBannerContext.Provider>
    </I18nProvider>
  )
}

