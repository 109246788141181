import { useEffect, useState } from 'react'
import { consentCookiesExist, readConsent, disableProviders } from './cookieService'

export const useCookieBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Check if the user has already given consent, otherwise we need consent
  const needConsent = !consentCookiesExist();
  const consents = readConsent()

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (needConsent) {
      disableProviders()
      openModal()
    }
  }, [needConsent])

  return {
    isModalOpen,
    openModal,
    closeModal,
    consentSettings: consents,
  }
}
